@import "./styles/global.scss";

#RBOTExt {
  font-size: .8rem;
  // height: 125%;
  // transform: scale(0.8);
  // transform-origin: 550px 0px;
  font-family: 'Fira Code', Tahoma, Verdana, sans-serif;
}

#RBOTExt * {
  transition: opacity .5s;
  transition: all 1s linear;
}