@import "../../../styles//global.scss";

.bgColor {
  background-image: $themeBGImage;
}

.brand {
  margin: auto;
  margin-right: auto !important;
}

.whiteColor {
  color: white;
}

.bigSize {
  font-size: 1.2rem;
}

.actionButton {
  &:hover {
    text-decoration: none;
  }
}