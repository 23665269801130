@import "shared/variables";
@import "shared/mixins";

/* make the customizations */
$theme-colors: (
  "info": $themeColor
);

// Your variable overrides
$body-bg: #fff;
$body-color: #111;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

@fontface {
  font-family: 'Fira Code', cursive;
  src: url('https://fonts.googleapis.com/css?family=Fira+Code:regular,bold,italic&subset=latin,latin-ext')
}