$themeColor: #377f94;
$themeBGImage: linear-gradient(#377f94 105px,#fff 105px,#fff);

$white: #fff;

$grey: #7e7e92;
$midGrey: #f4f4f7;
$darkGrey: #8b8b9e;

$blue: #3f80f7;
$babyBlue: #5adbf1;
$babyTreen: #4ce8ac;

$orange: #ffb950;
$pink: #f8498a;
$green: #598b59;
