@import "../../../../../styles/global.scss";
@import "../../../../../styles/shared/mixins";

.card {
  padding: 24px;
  width: 100%;
}

.cardText {
  color: $themeColor;
}

.marginRight {
  margin-right: 10px;
}

.dataTable {
  @include dataTable();
}

.skillButton {
  margin-top: 5px;
}