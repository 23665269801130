.accordionHeader {
  line-height: 30px;
  font-size: '.35rem';

  button {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
    color: rgb(55, 127, 148);
  };
}

.accordionBody {
  line-height: 25px;
  width: 100%;
  display: inline-block;
}