@import "../../../styles//global.scss";

.cardFooter {
  position: fixed;
  width: 100%;
  text-transform: none;
  text-align: center;
  background-image: $themeBGImage;

  bottom: 0;
  height: 39px;
  line-height: 39px;
  overflow: hidden;
  zoom: 1;
  margin: 0;
  padding: 0px;

  // Text center
  display: flex;
  align-items: center;
  justify-content: center;

  strong, em {
    margin-left: 5px;
    color: white;
    text-transform: uppercase;
  }
}

.cardFooterLink {
  display: contents;
}