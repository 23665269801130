@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  zoom: 1;
}

@mixin dataTable($themeColor: #377f94) {
  td {
    font-size: 1.5rem;
    font-weight: bold;
    height: 40px;
    line-height: 40px;

    &.dataValue {
      line-height: 25px;
      font-size: 1.2rem;
      color: $themeColor;
    }

    &:first-child {
      text-transform: uppercase;
      text-align: right;
      width: 18rem;
      padding: 10px;
      color: #593535cc !important;
    }
  }
}

@mixin placeholderColor($color: #86be42) {
  input::-webkit-input-placeholder {
    color: $color;
  }
  input:-moz-placeholder {
    color: $color;
  }
  input::-moz-placeholder {
    color: $color;
  }
  input:-ms-input-placeholder {
    color: $color;
  }
}

@mixin textOverflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin lineClamp($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
}

@mixin fontValue($family: "PTSans", $size: 24px, $color: #333) {
  font-family: $family;
  font-size: $size;
  color: $color;
}

@mixin dropShadow($x: 2px, $y: 2px, $z: 3px, $blur: 0, $color: $companies) {
  box-shadow: $x $y $z $blur $color;
  -moz-box-shadow: $x $y $z $blur $color;
  -webkit-box-shadow: $x $y $z $blur $color;
}

@mixin customScrollbar(
  $color: $color,
  $bgColor: $backgroundColor,
  $size: $size,
  $radius: $borderRadius
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin toRightLinearGradient($start, $stop) {
  background: -webkit-linear-gradient(left, $start, $stop);
  /* Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, $start, $stop);
  /* Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, $start, $stop);
  /* Firefox 3.6 to 15 */
  background: linear-gradient(to right, $start, $stop);
  /* Standard syntax */
}

@mixin toBottomLeftLinearGradient($start, $stop) {
  background: -webkit-linear-gradient(right top, $start, $stop);
  /* Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom left, $start, $stop);
  /* Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom left, $start, $stop);
  /* Firefox 3.6 to 15 */
  background: linear-gradient(to bottom left, $start, $stop);
  /* Standard syntax */
}

@mixin customCheckbox(
  $borderColor: $borderColor,
  $color: $color,
  $checkedBGColor: $checkedBGColor,
  $width: $width
) {
  input[type="checkbox"] {
    -webkit-appearance: none;
    position: relative;
    width: $width;
    height: $width;
    border: 1px solid $borderColor;
    border-radius: 20%;
    outline: none;
    box-shadow: 0 0 0px 0px $color inset;
    background-color: $color;
    display: inline-block;
  }
  input[type="checkbox"]:hover {
    box-shadow: 0 0 0px 0px $color inset;
  }
  input[type="checkbox"]:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 20%;
    background: $color;
  }
  input[type="checkbox"]:checked:before {
    background: asset_url("checked.svg") no-repeat;
    width: 21px;
    left: 2px;
    position: absolute;
    content: "";
  }
}

@mixin font($size, $family) {
  font-size: $size;
  font-family: $family;
}

@mixin resetPre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  margin-bottom: 0;
}

// overide wuilinmaster problem of input:focus;
@mixin textInputFocusBorder($size, $type, $color) {
  &[type]:not([readonly]):focus {
    border: $size $type $color;
    border-bottom: $size $type $color !important;
  }
}

@mixin extStyle($width: 576px) {
  @media (max-width: $width) {
    @content;
  }
}
