@import "../../styles/global.scss";
@import "../../styles/shared/mixins";

.ranBotApp {
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
  font-family: 'Fira Code', Tahoma, Verdana, sans-serif;
  @include customScrollbar(rgba($themeColor, 0.5), #222, 8px, 4px);

  td {
    overflow-wrap: break-word;
  }
}

.spinnerBorder {
  width: 150px !important;
  height: 150px !important;
  border-width: 8px !important;
}

.card {
  margin-top: 15%;
  margin-bottom: 5%;
}

.cardBody {
  margin: 10px;
  box-shadow: rgba(114, 114, 114, 0.6) 0px 0px 40px;
}